import React from 'react'
import { graphql } from 'gatsby'
// import { Helmet } from 'react-helmet'
// import get from 'lodash/get'
import Layout from '../components/layout'
import ArtistNav from '../components/artistNavBar'
import { renderRichText} from "gatsby-source-contentful/rich-text"
import {INLINES} from "@contentful/rich-text-types"
import {Link} from 'gatsby'
import SEO from "../components/seo"
import './artist-press.css'


class ArtistExhibitionsTemplate extends React.Component {

  render() {
    const options = {
      renderNode: {
        [INLINES.ENTRY_HYPERLINK]: (node, children) => {
          return <Link href={`/exhibitions/${node.data.target.slug}`} >{children}</Link>
          }
      }
    }

    const artist = this.props.data.contentfulArtist
    const press = artist.selectedExhibitions === null? <h1>Selected Exhibitions Coming Soon</h1> : renderRichText(artist.selectedExhibitions, options)


    return (
      <Layout location={this.props.location}>
        <SEO 
          title={`${artist.name}`}
          description={`Selected exhibitions by artist ${artist.name}`}
        />
        <div style={{ background: '#fff' }}>
          <div className="artistHeader">{artist.name}</div>
            <ArtistNav slug={artist.slug}/>
            <div className="richtext-cont">{press}</div>
        </div>
      </Layout>
    )
  }
}

export default ArtistExhibitionsTemplate

export const pageQuery = graphql`
  query artistExhibitions($slug: String!) {
    contentfulArtist(slug: { eq: $slug }) {
        name
        slug
        selectedExhibitions {
          raw
          references {
            ... on ContentfulExhibition {
              # contentful_id is required to resolve the references
              contentful_id
              title
              slug
            }
          } 
        }
    }
  }
`